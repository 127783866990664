(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/tipsen-nav.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/tipsen-nav.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  Link
} = ReactRouterDOM;
const {
  ItemsMenu,
  LinkItem,
  ItemsMenuIcon
} = svs.ui.ReactItemsMenu;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  useEffect,
  useState
} = React;
const {
  baseUrl,
  routes
} = svs.core.data;
const {
  menuItems
} = svs.tipsen.data || {};
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  useLocation,
  matchPath
} = ReactRouterDOM;
const {
  detect
} = svs.core;
let navEl = document.querySelector('#tipsen-nav');
if (navEl === null) {
  navEl = document.createElement('div');
  document.body.appendChild(navEl);
}
let mainEl = document.querySelector('#tipsen');
if (mainEl === null) {
  mainEl = document.createElement('div');
  document.body.appendChild(mainEl);
}
const getNumber = str => parseInt(str, 10);
const getSelectedIndex = items => {
  const slicedItems = items.slice(0);
  slicedItems.sort((a, b) => b.url.length - a.url.length);
  let selectedIndex = 0;
  for (let i = 0; i < slicedItems.length; ++i) {
    if (slicedItems[i].url.length > location.pathname.length) {
      continue;
    }
    if (location.pathname.startsWith(slicedItems[i].url)) {
      selectedIndex = i;
      break;
    }
  }
  return items.indexOf(slicedItems[selectedIndex]);
};
const isClientRoute = url => {
  for (const modulePath of Object.keys(routes)) {
    const match = matchPath(url, {
      path: baseUrl + modulePath,
      exact: true,
      strict: false
    });
    if (match !== null) {
      return true;
    }
  }
  return false;
};
const flaggedMenuItems = (menuItems || []).map(menuItem => _objectSpread(_objectSpread({}, menuItem), {}, {
  isClientRoute: isClientRoute(menuItem.url)
})).filter(menuItem => {
  if (menuItem.ftName) {
    return detect.feature(menuItem.ftName);
  }
  return true;
});
const TipsenNav = () => {
  const isWideScreen = useMediaQuery(breakpoints.up(BREAKPOINT_SM));
  const [productBanding] = useBranding();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(() => getSelectedIndex(flaggedMenuItems));
  useEffect(() => {
    const ownPadding = getNumber(getComputedStyle(mainEl).paddingBottom);
    const tabbedNavStyle = getComputedStyle(navEl);
    const shadowYOffset = getNumber(tabbedNavStyle.getPropertyValue('--box-shadow-y-offset'));
    const shadowBlur = getNumber(tabbedNavStyle.getPropertyValue('--box-shadow-blur-radius'));
    if (isWideScreen) {
      mainEl.style.paddingBottom = "".concat(Number(ownPadding), "px");
    } else {
      mainEl.style.paddingBottom = "".concat(Number(ownPadding) + shadowYOffset + shadowBlur + navEl.offsetHeight, "px");
    }
  }, [isWideScreen]);
  useEffect(() => {
    setSelectedIndex(getSelectedIndex(flaggedMenuItems));
  }, [location.pathname]);
  return ReactDOM.createPortal(React.createElement(ItemsMenu, {
    branding: "".concat(productBanding, "-main-nav"),
    centered: true,
    className: "tipsen-items-menu",
    compact: !isWideScreen
  }, flaggedMenuItems.map((menuItem, index) => {
    const linkItemProps = {};
    if (menuItem.isClientRoute) {
      linkItemProps.Component = Link;
      const url = menuItem.url.slice(baseUrl.length);
      linkItemProps.to = {
        pathname: url.length === 0 ? '/' : url
      };
    } else {
      linkItemProps.Component = 'a';
      linkItemProps.href = menuItem.url;
    }
    return React.createElement(LinkItem, _extends({
      badge: menuItem.extraConfig.isBadge,
      badgeNr: menuItem.extraConfig.badgeNr,
      key: "".concat(menuItem.label, ";").concat(menuItem.url),
      label: menuItem.label,
      onClick: () => setSelectedIndex(index),
      selected: selectedIndex === index
    }, linkItemProps), Boolean(menuItem.extraConfig.icon) && React.createElement(ItemsMenuIcon, {
      icon: menuItem.extraConfig.icon,
      position: isWideScreen ? 'left' : 'top',
      size: "200"
    }));
  })), navEl);
};
setGlobal('svs.components.tipsen.modularBase.TipsenNav', TipsenNav);

 })(window);