(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/skeleton/route-play-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/skeleton/route-play-fallback.js');
"use strict";

const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const RoutePlayFallback = () => React.createElement(React.Fragment, null, React.createElement("h2", {
  className: "spelomgang-skeleton-title skeleton-title"
}, "Spelomg\xE5ng"), React.createElement("div", {
  className: "padding-top-2 padding-right-2 padding-bottom-1 padding-left-2 skeleton-box spelomgang-skeleton"
}, React.createElement("div", {
  className: "padding-right-2"
}, React.createElement("div", {
  className: "margin-bottom-2 skeleton-match"
}, React.createElement(SkeletonCircle, {
  className: "margin-right-1",
  size: 40
}), React.createElement(SkeletonLine, {
  className: "skeleton-match-title"
})), React.createElement("div", null, React.createElement(SkeletonLine, {
  className: "margin-right-2 margin-left-2 margin-bottom-1 skeleton-match-info"
}), React.createElement(SkeletonLine, {
  className: "margin-right-2 margin-left-2 margin-bottom-1 skeleton-match-info"
}), React.createElement(SkeletonLine, {
  className: "margin-right-2 margin-left-2 skeleton-match-info"
}))), React.createElement("div", null, React.createElement("div", {
  className: "margin-left-4 margin-bottom-2 skeleton-game-items"
}, React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
})), React.createElement("div", {
  className: "margin-left-4 margin-bottom-2 skeleton-game-items"
}, React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
})), React.createElement("div", {
  className: "margin-left-4 skeleton-game-items"
}, React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-flag"
}), React.createElement(SkeletonLine, {
  className: "skeleton-game-team-title"
})))), React.createElement("h2", {
  className: "skeleton-title"
}, "Hur vill du spela?"), React.createElement("div", {
  className: "skeleton-box spelmenu-skeleton"
}, React.createElement("div", {
  className: "spelmenu-skeleton-item"
}, React.createElement(SkeletonLine, {
  className: "skeleton-menu-icon"
}), React.createElement(SkeletonLine, {
  className: "skeleton-menu-title"
})), React.createElement("div", {
  className: "spelmenu-skeleton-item"
}, React.createElement(SkeletonLine, {
  className: "skeleton-menu-icon"
}), React.createElement(SkeletonLine, {
  className: "skeleton-menu-title"
})), React.createElement("div", {
  className: "spelmenu-skeleton-item"
}, React.createElement(SkeletonLine, {
  className: "skeleton-menu-icon"
}), React.createElement(SkeletonLine, {
  className: "skeleton-menu-title"
})), React.createElement("div", {
  className: "spelmenu-skeleton-item"
}, React.createElement(SkeletonLine, {
  className: "skeleton-menu-icon"
}), React.createElement(SkeletonLine, {
  className: "skeleton-menu-title"
}))));
setGlobal('svs.components.tipsen.modularBase.RoutePlayFallback', RoutePlayFallback);

 })(window);