(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/handle-path.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/handle-path.js');
"use strict";

var _svs$core$data;
const {
  useLocation
} = ReactRouterDOM;
const routesMetaData = (_svs$core$data = svs.core.data) === null || _svs$core$data === void 0 ? void 0 : _svs$core$data.routesMetaData;
const routesMetaDataPaths = Object.keys(svs.core.data.routesMetaData || []).sort((a, b) => a.length - b.length);
const replacePlaceholders = function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const placeholders = text.match(/({{([\w-]*)}})+/g) || [];
  placeholders.forEach(placeholder => {
    text = text.replace(new RegExp(placeholder, 'g'), data[placeholder.replace(/{|}/g, '')] || '');
  });
  return text;
};
const setTitleAndMetaDescription = function () {
  var _ref;
  let routeMetaData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const metaData = (_ref = routeMetaData !== null && routeMetaData !== void 0 ? routeMetaData : routesMetaData === null || routesMetaData === void 0 ? void 0 : routesMetaData['/']) !== null && _ref !== void 0 ? _ref : '';
  if (metaData) {
    document.title = replacePlaceholders(metaData.title, data);
    const metaDescriptionElement = document.querySelector('meta[name=description]');
    if (metaDescriptionElement) {
      metaDescriptionElement.content = replacePlaceholders(metaData.metaDescription, data);
    }
  }
};
const HandlePath = _ref2 => {
  let {
    data
  } = _ref2;
  const {
    pathname
  } = useLocation();
  const routesMetaDataPathMatch = React.useMemo(() => {
    const routeMetaData = routesMetaData === null || routesMetaData === void 0 ? void 0 : routesMetaData[pathname];
    if (routeMetaData) {
      return routeMetaData;
    }
    const routeMetaDataPath = routesMetaDataPaths.find(routesMetaDataPath => ReactRouterDOM.matchPath(pathname, {
      path: routesMetaDataPath,
      exact: true,
      strict: false
    }));
    return routesMetaData === null || routesMetaData === void 0 ? void 0 : routesMetaData[routeMetaDataPath];
  }, [pathname]);
  if (routesMetaDataPathMatch) {
    setTitleAndMetaDescription(routesMetaDataPathMatch, data);
  } else {
    setTitleAndMetaDescription(data);
  }
  return null;
};
HandlePath.propTypes = {
  data: PropTypes.object
};
setGlobal('svs.components.tipsen.modularBase.HandlePath', HandlePath);

 })(window);