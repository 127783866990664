(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/skeleton/route-wager-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/skeleton/route-wager-fallback.js');
"use strict";

const {
  ReactPaper: Paper
} = svs.ui;
const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const RouteWagerCouponFallback = _ref => {
  let {
    isWideScreen
  } = _ref;
  return isWideScreen ? React.createElement("div", {
    className: "coupon-match-skeleton"
  }, React.createElement(SkeletonCircle, {
    className: "coupon-match-number-skeleton",
    size: 36
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  })) : React.createElement("div", {
    className: "coupon-match-mobile-skeleton"
  }, React.createElement(SkeletonCircle, {
    className: "coupon-match-mobile-number-skeleton",
    size: 30
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement("div", null, React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-team-skeleton"
  })));
};
const RouteWagerFallback = () => {
  const isWideScreen = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  return React.createElement("div", {
    className: "pg_route_wager pg_route_wager--center"
  }, React.createElement("div", {
    className: "pg_route_wager_grid"
  }, React.createElement(Paper, {
    branding: "sport",
    className: "pg_route_wager__windiv"
  }, React.createElement(SkeletonLine, {
    className: "pg_route_wager__windiv__row"
  }), React.createElement(SkeletonLine, {
    className: "pg_route_wager__windiv__row"
  }), React.createElement(SkeletonLine, {
    className: "pg_route_wager__windiv__row"
  }), React.createElement(SkeletonLine, {
    className: "pg_route_wager__windiv__row"
  }), React.createElement(SkeletonLine, {
    className: "pg_route_wager__windiv__row"
  })), React.createElement("div", {
    className: "pg_route_wager__coupon_grid"
  }, React.createElement(Paper, {
    branding: "sport",
    className: "pg_route_wager__coupon"
  }, React.createElement(RouteWagerCouponFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RouteWagerCouponFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RouteWagerCouponFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RouteWagerCouponFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RouteWagerCouponFallback, {
    isWideScreen: isWideScreen
  }))), React.createElement(Paper, {
    branding: "sport",
    className: "pg_route_wager__stats"
  }, React.createElement("div", {
    className: "coupon-match-info-skeleton"
  }, React.createElement(SkeletonLine, {
    className: "coupon-match-info-title-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  })), React.createElement("div", {
    className: "coupon-match-info-skeleton"
  }, React.createElement(SkeletonLine, {
    className: "coupon-match-info-title-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  })))));
};
setGlobal('svs.components.tipsen.modularBase.RouteWagerFallback', RouteWagerFallback);

 })(window);