(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/skeleton/route-wager-list-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/skeleton/route-wager-list-fallback.js');
"use strict";

const {
  ReactPaper: Paper
} = svs.ui;
const {
  SkeletonLine
} = svs.components.lbUi;
const RouteWagerListGroupFallback = () => React.createElement(React.Fragment, null, React.createElement(SkeletonLine, {
  className: "pg_route_wager_list__title"
}), React.createElement(Paper, {
  branding: "sport",
  className: "pg_route_wager_list__paper"
}, React.createElement(SkeletonLine, {
  className: "pg_route_wager_list__paper__content"
}), React.createElement(SkeletonLine, {
  className: "pg_route_wager_list__paper__content"
}), React.createElement(SkeletonLine, {
  className: "pg_route_wager_list__paper__content"
})));
const RouteWagerListFallback = () => React.createElement("div", {
  className: "pg_route_wager_list__center"
}, React.createElement(RouteWagerListGroupFallback, null), React.createElement(RouteWagerListGroupFallback, null));
setGlobal('svs.components.tipsen.modularBase.RouteWagerListFallback', RouteWagerListFallback);

 })(window);