(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/modular-base/assets/javascripts/skeleton/route-result-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/modular-base/assets/javascripts/skeleton/route-result-fallback.js');
"use strict";

const {
  ReactPaper: Paper
} = svs.ui;
const {
  SkeletonLine
} = svs.components.lbUi;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const RouteResultFallback = () => {
  const isWideScreen = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  return React.createElement("div", {
    className: "result-layout-skeleton"
  }, React.createElement("div", {
    className: "calendar-holder"
  }, React.createElement(SkeletonLine, {
    height: 3,
    style: {
      width: '50%'
    }
  })), React.createElement("div", {
    className: "grid-row"
  }, React.createElement("div", {
    className: "col-md-".concat(isWideScreen ? 7 : 12)
  }, React.createElement(Paper, {
    branding: "sport",
    className: "result-coupon"
  }, React.createElement(SkeletonLine, {
    height: 36
  })), React.createElement("div", {
    className: "table-holder f-content"
  }, React.createElement("h2", {
    className: "h2"
  }, "Utdelning"), React.createElement(Paper, {
    branding: "sport",
    className: "result-coupon"
  }, React.createElement(SkeletonLine, {
    height: 12
  }))), React.createElement("div", {
    className: "table-holder f-content"
  }, React.createElement("h2", {
    className: "h2"
  }, "Sportkryss"), React.createElement(Paper, {
    branding: "sport",
    className: "result-coupon"
  }, React.createElement(SkeletonLine, {
    height: 12
  })))), isWideScreen && React.createElement("div", {
    className: "result-sticky-div col-md-5"
  }, React.createElement(Paper, {
    branding: "sport",
    className: "side-nav-holder"
  }, React.createElement(SkeletonLine, {
    height: 18
  })))));
};
setGlobal('svs.components.tipsen.modularBase.RouteResultFallback', RouteResultFallback);

 })(window);